body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blur {
  color: transparent;
  text-shadow: 0 0 1px hsl(25, 0%, 90%, 1);
}

.h {
  border-color: white;
  border-width: 1px;
}

@font-face {
  font-family: "NewAthena";
  src: local("NewAthena"), url(./fonts/NewAthena.ttf) format("truetype");
}

@font-face {
  font-family: "NewAthenaHeading";
  font-weight: 700;
  src: local("NewAthena"), url(./fonts/NewAthena.ttf) format("truetype");
}

@font-face {
  font-family: "Jupiter";
  src: local("Jupiter"), url(./fonts/Jupiter.otf) format("truetype");
}

@font-face {
  font-family: "JupiterHeading";
  font-weight: 700;
  src: local("Jupiter"), url(./fonts/Jupiter.otf) format("truetype");
}

@font-face {
  font-family: "Celestia";
  src: local("Celestia"), url(./fonts/Celestia.otf) format("truetype");
}

@font-face {
  font-family: "CelestiaHeading";
  font-weight: 700;
  src: local("Celestia"), url(./fonts/Celestia.otf) format("truetype");
}

@font-face {
  font-family: "Segan";
  src: local("Segan"), url(./fonts/Segan.ttf) format("truetype");
}

@font-face {
  font-family: "SeganHeading";
  font-weight: 700;
  src: local("Segan"), url(./fonts/Segan.ttf) format("truetype");
}

@font-face {
  font-family: "Moon";
  src: local("Moon"), url(./fonts/Moon.otf) format("truetype");
}

@font-face {
  font-family: "MoonHeading";
  font-weight: 700;
  src: local("Moon"), url(./fonts/Moon.otf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "InterThin";
  src: local("Inter"), url(./fonts/Inter-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Passion";
  src: local("Passion"), url(./fonts/Passion.ttf) format("truetype");
}

@font-face {
  font-family: "PassionHeading";
  font-weight: 700;
  src: local("Passion"), url(./fonts/Passion.ttf) format("truetype");
}

@font-face {
  font-family: "Zodiac";
  src: local("Zodiac"), url(./fonts/Zodiac.otf) format("truetype");
}

@font-face {
  font-family: "ZodiacHeading";
  font-weight: 700;
  src: local("Zodiac"), url(./fonts/Zodiac.otf) format("truetype");
}
