#addreth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.connect-button {
  width: 100%;
}
