.number {
  font: 1rem "Zodiac", sans-serif;
  font-weight: bold;
}

.future {
  display: inline-block;
  color: rgb(42, 42, 42);
}

.past {
  display: inline-block;
  color: rgb(255, 255, 255);
}

.current {
  color: white;
}

.current::before {
  content: "";
  position: absolute;
  background: radial-gradient(
    #6498e6,
    #012458,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
  top: -15px; /* Adjust these values as needed */
  left: -15px;
  right: -15px;
  bottom: -15px;
  z-index: -11111; /* Places the shadow below the content */
  animation: irregularFlicker 5s infinite alternate-reverse;
}

.outer {
  box-shadow: 0 0 50px 0px rgba(255, 255, 255, 0.6);
  animation: glow 50s infinite alternate-reverse;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 50px 0px rgba(255, 255, 255, 0);
  }

  10% {
    box-shadow: 0 0 50px 0px rgba(255, 255, 255, 0.2);
  }

  20% {
    box-shadow: 0 0 50px 0px rgba(255, 255, 255, 0);
  }

  30% {
    box-shadow: 0 0 50px 5px rgba(255, 255, 255, 0.3);
  }

  40% {
    box-shadow: 0 0 50px 0px rgba(255, 255, 255, 0.5);
  }

  50% {
    box-shadow: 0 0 50px 0px rgba(255, 255, 255, 0.2);
  }

  60% {
    box-shadow: 0 0 50px 0px rgba(255, 255, 255, 0.1);
  }

  70% {
    box-shadow: 0 0 50px 5px rgba(255, 255, 255, 0.3);
  }

  80% {
    box-shadow: 0 0 50px 0px rgba(255, 255, 255, 0.2);
  }

  90% {
    box-shadow: 0 0 50px 4px rgba(255, 255, 255, 0.4);
  }

  100% {
    box-shadow: 0 0 50px 0px rgba(255, 255, 255, 0.1);
  }
}
@keyframes irregularFlicker {
  0% {
    text-shadow: 1;
  }

  10% {
    opacity: 0.9;
  }

  20% {
    opacity: 1;
  }

  30% {
    opacity: 0.7;
  }

  40% {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  60% {
    opacity: 0.8;
  }

  70% {
    opacity: 0.9;
  }

  80% {
    opacity: 0;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0.7;
  }
}
