@keyframes fadeInOut {
  0% {
    display: none;
  }

  20%,
  100% {
    display: inline-block;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
