.responsive-iframe {
  position: relative;
  width: 100%; /* Ensure full width */
  height: 0;
  padding-bottom: 100%; /* Adjust based on the aspect ratio of your SVG */
  overflow: visible;
}
.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; /* To remove any default border */
}
