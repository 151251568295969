.carousel {
  border: 1px solid white;
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  padding: 10px 0;
  position: relative;
  bottom: 0;
  width: 100%;
  align-items: left;
  justify-content: left;
  vertical-align: middle;
}

.carousel-item {
  flex: 0 0 auto;
  width: itemWidth;
  height: 300px; /* 30% of the viewport height */
  margin-right: 10px;
  border: none; /* Optional, for styling */
  animation: 25s scroll ease-in-out infinite alternate;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(
      -500%
    ); /* Large percentage to ensure scrolling through all items */
  }
}
